<script lang="ts">
  import { config, state } from "@/hmi";
  import { numFmt } from "@/stores";
  import { repeat } from "@/utils";
  import { _ } from "svelte-i18n";
  import { Phases, type DefrostProgram } from "./defrost";

  export let data: DefrostProgram;
  export let selectedPhase: string;

  function onPointerDown(event: PointerEvent) {
    let el = event.target as HTMLDivElement;
    if (!el) return;
    let phase = el.getAttribute("data-phase");
    if (!phase) return;
    if (phase === selectedPhase) {
      selectedPhase = undefined;
      return;
    }
    selectedPhase = phase;
  }
</script>

<section on:pointerdown={onPointerDown}>
  <div data-phase={Phases.preHeating} class:selected={selectedPhase == Phases.preHeating}>
    {$_("$$defrost.pre-heating")}
    <hr />
    <div>{$_("$$defrost.drain_heat")}: {data.preHeating.drainHeat}%</div>
    <div>{$_("$$defrost.drip_tray_heat")}: {data.preHeating.dripTrayHeat}%</div>
    <div>{$_("$$defrost.max_time")}: {data.preHeating.maxTime}{$_("$$short.minute")}</div>
    <div>{$_("$$defrost.temp_below_room")}: {$numFmt(data.preHeating.t, -1)}{$state.tempUnit}</div>
  </div>
  <div data-phase={Phases.heatHigh} class:selected={selectedPhase == Phases.heatHigh}>
    {$_("$$defrost.heat-high")}
    <hr />
    <div>{$_("$$defrost.drain_heat")}: {data.heatHigh.drainHeat}%</div>
    <div>{$_("$$defrost.drip_tray_heat")}: {data.heatHigh.dripTrayHeat}%</div>
    <div>{$_("$$defrost.time")}: {data.heatHigh.time}{$_("$$short.second")}</div>
    <div>{$_("$$defrost.max_temperature")}: {$numFmt(data.heatHigh.maxTemp, -1)}{$state.tempUnit}</div>
    <div>&nbsp;</div>
    {#each repeat($config.general.evaporatorCount) as _x, i}
      <div>{$_("$$defrost.evap")}{i + 1}: {data.heatHigh.evapHeat[i]}%</div>
    {/each}
  </div>
  <div data-phase={Phases.heatLow} class:selected={selectedPhase == Phases.heatLow}>
    {$_("$$defrost.heat-low")}
    <hr />
    <div>{$_("$$defrost.drain_heat")}: {data.heatLow.drainHeat}%</div>
    <div>{$_("$$defrost.drip_tray_heat")}: {data.heatLow.dripTrayHeat}%</div>
    <div>{$_("$$defrost.max_time")}: {data.heatLow.maxTime}{$_("$$short.minute")}</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    {#each repeat($config.general.evaporatorCount) as _x, i}
      <div>{$_("$$defrost.evap")}{i + 1}: {data.heatLow.evapHeat[i]}%/{$numFmt(data.heatLow.evapStopTemp[i])}{$state.tempUnit}</div>
    {/each}
  </div>
  <div data-phase={Phases.dripTime} class:selected={selectedPhase == Phases.dripTime}>
    {$_("$$defrost.drip-time")}
    <hr />
    <div>{$_("$$defrost.drain_heat")}: {data.dripTime.drainHeat}%</div>
    <div>{$_("$$defrost.drip_tray_heat")}: {data.dripTime.dripTrayHeat}%</div>
    <div>{$_("$$defrost.time")}: {data.dripTime.time}{$_("$$short.second")}</div>
  </div>
  <div data-phase={Phases.preCooling} class:selected={selectedPhase == Phases.preCooling}>
    {$_("$$defrost.pre-cooling")}
    <hr />
    <div>{$_("$$defrost.drain_heat")}: {data.preCooling.drainHeat}%</div>
    <div>{$_("$$defrost.drip_tray_heat")}: {data.preCooling.dripTrayHeat}%</div>
    <div>{$_("$$defrost.max_time")}: {data.preCooling.maxTime}{$_("$$short.minute")}</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    {#each repeat($config.general.evaporatorCount) as _x, i}
      <div>{$_("$$defrost.evap")}{i + 1}: {$numFmt(data.preCooling.evapTemp[i])}{$state.tempUnit}</div>
    {/each}
  </div>
</section>

<style lang="scss">
  section {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    > div {
      padding: 1rem 0;
      text-align: center;
      font-family: hmiFontBold;
      font-size: 1.8rem;
      color: var(--clr-company);

      & + div {
        border-left: 1px solid var(--clr-primary-dimmed);
      }

      &.selected {
        background-color: var(--clr-primary-dimmed);
        color: var(--clr-white);
      }

      > div {
        font-family: hmiFont;
        font-size: 1.5rem;
        margin: 1rem;
        color: var(--clr-primary);
      }

      > * {
        pointer-events: none;
      }
    }
  }
</style>
