<script lang="ts">
  import { config, state } from "@/hmi";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { usesFarenheight } from "@/stores";
  import { CtoF, repeat } from "@/utils";
  import { _ } from "svelte-i18n";
  import type { DefrostProgram } from "./defrost";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: DefrostProgram;

  $: tadj = $usesFarenheight ? CtoF : (t: number) => t;

  $: heatLow = data.heatLow;
</script>

<div class="edit-container">
  <div class="column">
    <label>{$_("$$defrost.drain_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drain_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={heatLow.drainHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <label>{$_("$$defrost.drip_tray_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drip_tray_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={heatLow.dripTrayHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <hr />

    <label>{$_("$$defrost.max_time")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.max_time")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={120}
        bind:value={heatLow.maxTime}
        hidelabel
        on:change
      />
      <span>{$_("$$short.minute")}</span>
    </div>

    <hr />

    {#each repeat($config.general.evaporatorCount) as _x, i}
      <label>{$_("$$defrost.heat_ev#")}{i + 1}:</label>
      <div class="flex-line">
        <NumberInput
          label={$_("$$defrost.heat_ev#")}
          width="18rem"
          incdec
          step={1}
          min={0}
          max={100}
          bind:value={heatLow.evapHeat[i]}
          hidelabel
          on:change
        />
        <span>%</span>
      </div>
    {/each}

    <hr />

    {#each repeat($config.general.evaporatorCount) as _x, i}
      <label>{$_("$$defrost.stop_temp_ev#")}{i + 1}:</label>
      <div class="flex-line">
        <NumberInput
          label={$_("$$defrost.stop_temp_ev#")}
          width="18rem"
          incdec
          step={1}
          min={Math.floor(tadj(-20))}
          max={Math.ceil(tadj(30))}
          bind:value={heatLow.evapStopTemp[i]}
          hidelabel
          on:change
        />
        <span>{$state.tempUnit}</span>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  label {
    white-space: nowrap;
  }

  .column {
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
