<script lang="ts">
  import hmi, { equipment, state } from "@/hmi";
  import { usesFarenheight } from "@/stores";
  import HourglassIcon from "@/svg/HourglassIcon.svelte";
  import PadlockIcon from "@/svg/PadlockIcon.svelte";
  import { CtoF } from "@/utils";
  import { _ } from "svelte-i18n";
  import Cycle from "./Cycle.svelte";

  export let size = "9rem";
  export let canSteam = true;
  export let disableAnimations = false;

  const cycle = hmi.getObjectStore<DTO.AirCycleStatus>("AirCycleStatus");
  const steamPause = hmi.getValueStore("sys.steam_pause");
  const steampot = hmi.getObjectStore<DTO.SteampotStatus>("SteampotStatus");

  $: hasAirHeatOption = $equipment?.includes("AIR_HEATING_ELEMENTS");
  $: hasSteamOption = $equipment?.includes("STEAM_RODS_IN_STEAM_POT");

  function scaleTempForUser(t: number) {
    return $usesFarenheight ? CtoF(t) : t;
  }
</script>

<div class="wrapper">
  <div class="side" class:hide={!hasAirHeatOption}>
    <div class="text">{$_("proofing.heat")}</div>
    <div class="val">{Math.abs(Math.round($cycle?.heating || 0))}%</div>
  </div>

  <Cycle
    active={$cycle?.progress !== undefined && !disableAnimations}
    progress={$cycle?.progress}
    steaming={$cycle?.steaming}
    heating={$cycle?.heating}
    cooling={!!$cycle?.cooling}
    steamIsFirst={$cycle?.steamIsFirst}
  />

  <div class="side" class:hide={!hasSteamOption}>
    <div>{$_("proofing.steam")}</div>
    <div class="val">{Math.round($cycle?.steaming || 0)}%</div>
    {#if $steamPause > 0}
      <div class="sub">
        <HourglassIcon height="2.2rem" />
        {$steamPause}s
      </div>
    {:else if $cycle?.steamBlocked}
      <div class="sub">
        <PadlockIcon height="2.2rem" />
        &lt;{scaleTempForUser($cycle.steamMinTemp)}{$state.tempUnit}
      </div>
    {:else if canSteam && $steampot?.blocked}
      <div class="sub">
        <PadlockIcon height="2.2rem" />
        {$steampot.remain}s
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .wrapper {
    --clr-track: #{$primary-dimmed};
    --clr-position: #{$company};
    --clr-steam: hsl(0, 100%, 99%);
    --clr-heat: hsl(0, 70%, 50%);
    --clr-cooling: hsl(240, 100%, 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 2rem;

    font-family: hmiFontThin;
    font-size: calc(var(--font-size, 4rem) * 0.75);
  }

  .side {
    text-align: center;
    min-width: calc(var(--font-size, 4rem) * 2.5);
    position: relative;

    .text {
      font-size: 2rem;
    }
    .val {
      font-family: hmiFont;
      font-size: var(--font-size, 4rem);
    }
    .sub {
      position: absolute;
      top: 6.5rem;
      display: flex;
      gap: 0.25rem !important;
      font-family: hmiFontThin !important;
      font-size: 1.8rem !important;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-inline: auto;
      gap: 1rem;
    }
  }

  .hide {
    visibility: hidden;
  }
</style>
