<script lang="ts">
  import { programs, state } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { AfterMode, hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let minTemp: number;
  export let maxTemp: number;

  const dispatch = createEventDispatcher();
  const modeItems = [
    { text: $_("Standby"), value: AfterMode.standby },
    { text: $_("Proofing"), value: AfterMode.proofing },
    { text: $_("Storage"), value: AfterMode.storage },
  ];

  if (!data.after) data.after = { mode: AfterMode.standby, t: 0, h: 0, light: false, uv: false };

  const makeListItem = (p: DTO.ProgramInfo) => ({ value: p.slot, text: `${p.slot}: ${p.name}` });

  // Only allow programs that are marked for use in the manual mode here
  $: storageProgramItems = $programs?.storage?.programs.filter((p) => p.use_in_manual).map((p) => makeListItem(p)) || [];
  $: proofingProgramItems = $programs?.proofing?.programs.filter((p) => p.use_in_manual).map((p) => makeListItem(p)) || [];

  $: after = data.after;
  $: ensureValidProgram(after);

  function ensureValidProgram(o: typeof data.after) {
    // Check for valid proofing program
    if (o.mode === AfterMode.proofing) {
      if (!proofingProgramItems?.length) {
        o.program = "";
        return;
      }
      if (-1 === proofingProgramItems.findIndex((item) => item.value === o.program)) o.program = proofingProgramItems[0].value;
    }

    // Check for valid storage program
    if (o.mode === AfterMode.storage) {
      if (!storageProgramItems?.length) {
        o.program = "";
        return;
      }
      if (-1 === storageProgramItems.findIndex((item) => item.value === o.program)) o.program = storageProgramItems[0].value;
    }
  }

  function triggerReactivity() {
    after = after;
    dispatch("change");
  }
</script>

<div class="edit-container">
  <label>{$_("mode")}:</label>
  <ListInput items={modeItems} bind:selectedValue={after.mode} on:change width="26rem" />

  {#if after.mode === AfterMode.proofing && proofingProgramItems?.length > 1}
    <label>{$_("settings")}:</label>
    <ListInput items={proofingProgramItems} bind:selectedValue={after.program} on:change width="100%" />
  {/if}

  {#if after.mode === AfterMode.proofing}
    <label>{$_("Temperature")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("Temperature")}
        width="18rem"
        incdec
        step={1}
        min={minTemp}
        max={maxTemp}
        bind:value={after.t}
        digit
        negative
        hidelabel
        on:change
      />
      <span>{$state.tempUnit}</span>
    </div>
  {/if}

  {#if after.mode === AfterMode.storage}
    <StorageSettings data={after} {minTemp} {maxTemp} {storageProgramItems} on:change={triggerReactivity} />
  {/if}

  {#if after.mode === AfterMode.proofing}
    <label>{$_("Humidity")}:</label>
    <div class="flex-line">
      <NumberInput label={$_("Humidity")} width="18rem" incdec step={1} min={0} max={100} bind:value={after.h} hidelabel on:change />
      <span>%</span>
    </div>
  {/if}

  <label>{$_("Light")}:</label>
  <Switch bind:checked={after.light} on:change />

  {#if $hasUV && after.mode === AfterMode.storage}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={after.uv} on:change />
  {/if}

  {#if after.mode === AfterMode.storage}
    <hr />
    <div class="add-cooling">
      <CheckBox label={$_("add_cooling_curve")} bind:checked={after.add_cooling_curve} on:change />
    </div>
  {/if}
</div>

<style lang="scss">
  .edit-container {
    --clr-inc-dec: gray;
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
    min-height: 34rem;
    line-height: 1;
  }

  .add-cooling {
    grid-column: 1 / span 2;
  }

  label {
    white-space: nowrap;
  }
</style>
