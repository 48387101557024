<script lang="ts">
  import { applyBrightness, config, mutateConfig } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { clickDelay as s_clickDelay } from "@/stores";
  import { clamp } from "@/utils";
  import { _ } from "svelte-i18n";
  import ConfigDialog from "./ConfigDialog.svelte";

  let lockTimeout = $config.system.lockTimeout || 0; // seconds
  let clickDelay = $config.system.clickDelay || 0; // milliseconds
  let userIdleTimeout = $config.system.userIdleTimeout || 0; // seconds
  let unlockDelay = $config.system.unlockDelay || 0; // seconds

  let appliedBrightness = 0;
  let brightnessString = localStorage.getItem("screen_brightness_2") || "50";
  appliedBrightness = clamp(parseInt(brightnessString, 10), 20, 100);
  let brightness = appliedBrightness;
  let ignore = localStorage.getItem("screen_ignore") === "true";

  $: if (brightness !== appliedBrightness) {
    appliedBrightness = brightness;
    localStorage.setItem("screen_brightness_2", brightness.toString());
    applyBrightness();
  }

  $: localStorage.setItem("screen_ignore", ignore.toString());

  function save() {
    s_clickDelay.set(clickDelay);
    mutateConfig((c) => {
      c.system.userIdleTimeout = userIdleTimeout;
      c.system.clickDelay = clickDelay;
      c.system.lockTimeout = lockTimeout;
      c.system.unlockDelay = unlockDelay;
    });
  }
</script>

<ConfigDialog title={$_("menu.screen")}>
  <div class="content">
    <div class="table">
      <!-- User idle timeout -->
      <label>{$_("$$screen.user_idle_timeout")}:</label>
      <NumberInput bind:value={userIdleTimeout} min={0} max={9999} on:change={save} />
      <span>s</span>
      <!-- Lock timeout -->
      <label>{$_("$$screen.lock_timeout")}:</label>
      <NumberInput bind:value={lockTimeout} min={0} max={9999} on:change={save} />
      <span>s</span>
      <!-- Unlock delay -->
      <label>{$_("$$screen.unlock_time")}:</label>
      <NumberInput bind:value={unlockDelay} min={0} max={9999} on:change={save} />
      <span>ms</span>
      <!-- Click delay -->
      <label>{$_("$$screen.click_time")}:</label>
      <NumberInput bind:value={clickDelay} min={0} max={5000} on:change={save} />
      <span>ms</span>
    </div>
    <!-- Brightness -->
    <input type="range" min="20" max="100" step="1" bind:value={brightness} />
    {$_("$$screen.brightness")}: {brightness}%
    <br />
    <br />
    <!-- Ignore on local device -->
    <CheckBox bind:checked={ignore}>Ignore user idle and lock timeout on this device</CheckBox>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    padding: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
    font-size: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    .table {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 2rem;
      align-items: center;
      margin-bottom: 4rem;
    }

    input {
      width: 70%;
    }
  }
</style>
