<script lang="ts">
  import ProgramManager from "@/config/programs/manager/ProgramManager.svelte";
  import ProgramManagerIcon from "@/svg/ProgramManagerIcon.svelte";

  import ConfigDialog from "@/config/ConfigDialog.svelte";
  import EditFrame from "./EditFrame.svelte";

  import { api } from "@/api";
  import dialog from "@/dialog";
  import hmi, { DEFAULT_DEBOUNCED_SAVE_MS, programs } from "@/hmi";
  import ListInput from "@/lib/ListInput.svelte";
  import { usesFarenheight } from "@/stores";
  import { CtoF, Events, eventBus, showDialog } from "@/utils";
  import { debounce, isEmpty, isEqual } from "lodash-es";
  import { onDestroy } from "svelte";
  import CurveDisplay from "./CurveDisplay.svelte";
  import EditFrameContent from "./_EditFrameContent.svelte";
  import { MAX_TEMP_C, MIN_TEMP_C, Phase, lookupPhaseTitle, toServerTempUnit, toUserTempUnit, type AutoProgram } from "./auto";

  const debounceSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);
  const autoStatus = hmi.getObjectStore<DTO.AutoStatus>("AutoStatus");

  let data: AutoProgram | undefined = undefined;
  let selectedPhase: string | undefined;
  let calculatedTimeString: string;

  let allPrograms: Record<string, string> = {};
  let selectedProgram = $autoStatus?.slot;
  let editingProgram: string = undefined;

  let backups = new Map<string, AutoProgram>(); // Backup of the programs stored with user temp units
  let canRevert = false;
  const debounceUpdateRevert = debounce(() => {
    canRevert = editingProgram && backups.has(editingProgram) && !isEqual(backups.get(editingProgram), data);
  }, 100);

  const editProps = {
    minTemp: $usesFarenheight ? Math.floor(CtoF(MIN_TEMP_C)) : MIN_TEMP_C,
    maxTemp: $usesFarenheight ? Math.ceil(CtoF(MAX_TEMP_C)) : MAX_TEMP_C,
    storageProgramItems: [] as ListItem[],
    proofingProgramItems: [] as ListItem[],
  };

  $: phaseTitle = lookupPhaseTitle(data, selectedPhase);
  $: autoProgramItems = Object.keys(allPrograms)
    .sort()
    .map((k) => ({ text: `${k}: ${allPrograms[k]}${k === $autoStatus?.slot ? "*" : ""}`, value: k }));

  loadData();

  async function loadData() {
    // selectedProgram = undefined;
    // editingProgram = undefined;
    if (!$programs?.auto?.programs) return;
    const makeListItem = (p: DTO.ProgramInfo) => ({ value: p.slot, text: `${p.slot}: ${p.name}` });
    try {
      editProps.storageProgramItems = $programs?.storage?.programs
        ? $programs.storage.programs.filter((p) => p.use_in_auto).map((p) => makeListItem(p))
        : [];
      editProps.proofingProgramItems = $programs?.proofing.programs
        ? $programs.proofing.programs.filter((p) => p.use_in_auto).map((p) => makeListItem(p))
        : [];
      allPrograms = {};
      for (let i = 0; i < $programs.auto.programs.length; i++) {
        allPrograms[$programs.auto.programs[i].slot] = $programs.auto.programs[i].name;
      }
      if (isEmpty(allPrograms)) allPrograms = { A: "Default" };
      selectedProgram = selectedProgram ?? $programs.auto.programs[0].slot;
      if (!selectedProgram) {
        selectedProgram = "A";
      }
      editProgram(selectedProgram);
    } catch (err) {
      console.error(err);
    }
  }

  async function editProgram(name: string) {
    try {
      const response = await api.loadProgram<AutoProgram>("auto", name);
      if (response) {
        toUserTempUnit(response);
        if (!backups.has(name)) {
          backups.set(name, structuredClone(response));
        }
        data = response;
      } else {
        data = await api.newProgram("auto");
        toUserTempUnit(data);
      }
      editingProgram = name;
    } catch (err) {
      console.error(err);
    }
  }

  onDestroy(() => {
    debounceSave.flush(); // Save immediately if there is a pending save
    debounceUpdateRevert.cancel();
    eventBus.emit(Events.reloadAutoProgram);
  });

  async function save() {
    if (!data || !editingProgram) return;
    let saveData = structuredClone(data);
    toServerTempUnit(saveData);

    api.saveProgram("auto", editingProgram, saveData).catch((err) => {
      console.error(err);
    });
  }

  function onChange(e: CustomEvent<{ redrawGraph?: boolean }>) {
    debounceUpdateRevert();
    debounceSave();
  }

  function onCloseEdit() {
    selectedPhase = undefined;
  }

  function onChangeProgram() {
    debounceSave.flush();
    editProgram(selectedProgram);
    selectedPhase = undefined;
  }

  function defaultStorageSlot() {
    return editProps.storageProgramItems.at(0)?.value || "S01";
  }

  function defaultProofingSlot() {
    return editProps.proofingProgramItems.at(0)?.value || "P01";
  }

  function onAddStorage2() {
    data.storage2 = { light: false, uv: false, t: data.storage1.t + 2, duration: 3600 * 3, program: defaultStorageSlot() };
    selectedPhase = undefined;
    debounceSave();
    data = data; // Force update
    debounceUpdateRevert();
  }

  function onRemoveStorage2() {
    delete data.storage2;
    selectedPhase = undefined;
    debounceSave();
    data = data; // Force update
    debounceUpdateRevert();
  }

  async function openProgramManager() {
    let { lastSelectedSlot } =
      (await showDialog<{ lastSelectedSlot: string | undefined }>(ProgramManager, {
        type: "auto",
        selected: selectedProgram,
      })) || {};
    console.log("lastSelectedSlot", lastSelectedSlot);
    if (lastSelectedSlot) {
      selectedProgram = lastSelectedSlot;
      onChangeProgram();
      debounceUpdateRevert();
    } else {
      selectedProgram = $programs?.auto?.programs?.length > 0 ? $programs.auto.programs[0].slot : undefined;
    }
    loadData();
  }

  async function revert() {
    if (!(await dialog.confirm("Are you sure you want to revert all changes?"))) return;
    data = structuredClone(backups.get(editingProgram));
    // Sync program names
    allPrograms[editingProgram] = data.name;
    allPrograms = allPrograms; // Trigger reactivity

    selectedPhase = undefined;
    debounceSave();
    debounceUpdateRevert();
  }

  function pickSide(phase: string) {
    switch (phase) {
      case Phase.preCooling:
      case Phase.retarding:
      case Phase.storage1:
      case Phase.storage2:
        return "right";
      default:
        return "left";
    }
  }
</script>

<ConfigDialog title="Auto Program" helpId="AUTO_PROGRAM" {revert} {canRevert}>
  <div class="flex-line space-between" slot="title_extra">
    <ListInput label="Program" items={autoProgramItems} width="28rem" bind:selectedValue={selectedProgram} on:change={onChangeProgram} />
    <button on:click={openProgramManager}>
      <ProgramManagerIcon style="color:white" />
    </button>
  </div>

  <div class="content">
    {#if data}
      {@const panelSide = pickSide(selectedPhase)}
      <CurveDisplay {data} bind:selectedPhase bind:calculatedThawingTimeString={calculatedTimeString} editMode {panelSide} />
      {#if selectedPhase && panelSide == "left"}
        <EditFrame title={phaseTitle} on:close={onCloseEdit} position="left">
          <EditFrameContent
            bind:data
            {editProps}
            {calculatedTimeString}
            {selectedPhase}
            on:change={onChange}
            on:add={onAddStorage2}
            on:remove={onRemoveStorage2}
          />
        </EditFrame>
      {/if}
      {#if selectedPhase && panelSide == "right"}
        <EditFrame title={phaseTitle} on:close={onCloseEdit} position="right">
          <EditFrameContent
            bind:data
            {editProps}
            {calculatedTimeString}
            {selectedPhase}
            on:change={onChange}
            on:add={onAddStorage2}
            on:remove={onRemoveStorage2}
          />
        </EditFrame>
      {/if}
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .content {
    font-size: 2.6rem;
    // margin: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
    display: grid;

    :global(.uv-light) {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
</style>
