<script lang="ts">
  import { state } from "@/hmi";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { usesFarenheight } from "@/stores";
  import { CtoF } from "@/utils";
  import { _ } from "svelte-i18n";
  import type { DefrostProgram } from "./defrost";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: DefrostProgram;

  $: tadj = $usesFarenheight ? CtoF : (t: number) => t;

  $: preHeating = data.preHeating;
</script>

<div class="edit-container">
  <div class="column">
    <label>{$_("$$defrost.drain_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drain_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={preHeating.drainHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <label>{$_("$$defrost.drip_tray_heat")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.drip_tray_heat")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={preHeating.dripTrayHeat}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>

    <hr />

    <label>{$_("$$defrost.max_time")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.max_time")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={60}
        bind:value={preHeating.maxTime}
        hidelabel
        on:change
      />
      <span>{$_("$$short.minute")}</span>
    </div>

    <label>{$_("$$defrost.temp_below_room")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.temp_below_room")}
        width="18rem"
        incdec
        step={1}
        min={Math.floor(tadj(0))}
        max={Math.ceil(tadj(10))}
        bind:value={preHeating.t}
        hidelabel
        on:change
      />
      <span>{$state.tempUnit}</span>
    </div>

    <label>{$_("$$defrost.fan")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("$$defrost.fan")}
        width="18rem"
        incdec
        step={1}
        min={0}
        max={100}
        bind:value={preHeating.fanSpeed}
        hidelabel
        on:change
      />
      <span>%</span>
    </div>
  </div>
</div>

<style lang="scss">
  label {
    white-space: nowrap;
  }

  .column {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
