<script lang="ts">
  import hmi from "@/hmi";

  const steamOn = hmi.getValueStore("cur.steam_on");
  const waterInlet = hmi.getValueStore("cur.water_inlet");
  const waterDrain = hmi.getValueStore("cur.water_drain");
  const amps = hmi.getValueStore("cur.steampot_amps");

  const emptyAmps = 0.1;
  let empty = true;

  $: {
    if ($steamOn) {
      window.setTimeout(() => {
        if ($steamOn && $amps < emptyAmps) empty = true;
        else if ($steamOn && $amps >= emptyAmps) empty = false;
      }, 1000);
    } else {
    }
  }

  $: steamReallyOn = $steamOn && $amps > emptyAmps ? 1 : 0;
</script>

<link rel="preload" href="/img/streampot/SteamPotEmptyOpen.png" as="image" />
<link rel="preload" href="/img/streampot/SteamPotEmptyClosed.png" as="image" />
<link rel="preload" href="/img/streampot/SteamPotWaterOpen.png" as="image" />
<link rel="preload" href="/img/streampot/SteamPotWaterClosed.png" as="image" />
<link rel="preload" href="/img/streampot/SteamWaterValveOff.png" as="image" />
<link rel="preload" href="/img/streampot/SteamWaterValveOn.png" as="image" />
<link rel="preload" href="/img/streampot/SteamWaterAni.webp" as="image" />
<link rel="preload" href="/img/streampot/SteamPowerAni.webp" as="image" />
<link rel="preload" href="/img/streampot/SteamAni.webp" as="image" />

<!-- FIXME: Remove zoom when we have new higher resolution images -->
<div class="steampot stacking" style="zoom: var(--img-zoom)">
  {#if empty}
    {#if $waterDrain}
      <img src="/img/streampot/SteamPotEmptyOpen.png" loading="lazy" draggable="false" />
    {:else}
      <img src="/img/streampot/SteamPotEmptyClosed.png" loading="lazy" draggable="false" />
    {/if}
  {:else if $waterDrain}
    <img src="/img/streampot/SteamPotWaterOpen.png" loading="lazy" draggable="false" />
  {:else}
    <img src="/img/streampot/SteamPotWaterClosed.png" loading="lazy" draggable="false" />
  {/if}
  <img src="/img/streampot/SteamWaterValveOff.png" loading="lazy" draggable="false" />
  <img src="/img/streampot/SteamWaterValveOn.png" loading="lazy" draggable="false" style:opacity={$waterInlet} />
  <img src="/img/streampot/SteamWaterAni.webp" loading="lazy" draggable="false" style:opacity={$waterInlet} />

  <img
    src="/img/streampot/SteamPowerAni.webp"
    data-no-top-margin
    loading="lazy"
    draggable="false"
    style:opacity={steamReallyOn}
    width="400"
    height="480"
    style:margin-top="40px"
    style:padding-left="20px"
  />
  <img
    src="/img/streampot/SteamAni.webp"
    data-no-top-margin
    loading="lazy"
    draggable="false"
    style:opacity={steamReallyOn}
    style:padding-top="20px"
    style:padding-left="40px"
    width="400"
    height="440"
  />
</div>

<style lang="scss">
  .steampot {
    margin-top: -4rem;
  }
  .stacking {
    display: inline-grid;
    > * {
      grid-area: 1/1;
      margin-top: 4rem;
    }
  }

  img {
    transition: opacity 0.1s ease-in-out;
    width: 313px;
    height: 400px;
  }

  img[data-no-top-margin] {
    margin-top: 0;
  }
</style>
